<template>
    <div class="b_main_banner">
        <div class="banner_image_abs">
            <div class="hero_image image_decor no-filters-mobile">
                <picture
                    v-if="isMobile"
                    v-full-image-path="content.mobileImage"
                />
                <picture v-else v-full-image-path="content.image" />
            </div>
            <div class="banner_image_decor">
                <picture v-full-image-path:assets="'banner_image_decor.svg'" />
            </div>
        </div>
        <div class="container">
            <div class="banner_content">
                <div class="banner_content_inner w100">
                    <div class="content">
                        <div class="sub_title">
                            <h4>{{ content.subtitle }}</h4>
                        </div>
                        <div class="title">
                            <h1>
                                <span>{{ content.title }}</span>
                                <span v-if="content.title2">
                                    <br />{{ content.title2 }}
                                </span>
                            </h1>
                        </div>
                    </div>
                    <div v-if="showButton" class="link_wrap">
                        <nuxt-link
                            :to="localePath(content.button.link)"
                            class="link_arrow"
                        >
                            {{ content.button.text }}
                        </nuxt-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: Object,
            required: true
        }
    },

    computed: {
        isMobile() {
            return !this.$device.isDesktopOrTablet;
        },

        showButton() {
            const { text, link } = this.content.button;

            return text && link;
        }
    }
};
</script>
